(function( $ ) {

    $.fn.complectCompositionOpen = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            console.log('complectCompositionOpen:', this)
            const jqContainer = $(this);
            const openLink = jqContainer.find('.open-link>a');
            const compositionDetail = jqContainer.find('.composition-detail')[0];
            const compositionDetailHolder = jqContainer.find('.composition-detail-holder')[0];

            if (!compositionDetail || compositionDetail === undefined) {
                console.error('complectCompositionOpen: no compositionDetail found by query ".composition-detail"');
                return false;
            }

            if (!compositionDetailHolder || compositionDetailHolder === undefined) {
                console.error('complectCompositionOpen: no compositionDetailHolder found by query ".composition-detail-holder"');
                return false;
            }

            openLink.click(function(e) {
                e.preventDefault();
                if (jqContainer.hasClass('open')) {
                    $(compositionDetail).height(0);
                    jqContainer.removeClass('open');
                } else {
                    const compositionDetailHeight = $(compositionDetailHolder).innerHeight();
                    $(compositionDetail).height(compositionDetailHeight);
                    jqContainer.addClass('open');
                }
                
            })

        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.compect-composition').complectCompositionOpen();
});
